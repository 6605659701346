import { Enums, getRenderingEngine, Types } from '@cornerstonejs/core';
import { Enums as ToolEnums } from '@cornerstonejs/tools';
import useMipStore from 'stores/mip';
import useWindowingStore from 'stores/windowing';
import { useCallback, useEffect, useRef } from 'react';
import { calculateSliceValues,
  calculateWindowingValues,
  getAndSetWindowingValues } from 'utils/cornerstone/tooling';
import { setMipDefaultValues } from 'utils/cornerstone/volumeUtils';
import { applyDefaultColorMap, getDefaultLesion } from 'utils/heatmaps/heatmaps';
import { getDefaultWindowingValues, getViewport, handleJumpToSlice } from 'utils/utilities';
import useActiveLesion from 'stores/activeLesion';
import useIsVesselReconstructionActive from 'stores/useVesselReconstruction';
import { RENDERING_ENGINE_ID } from '../constants';

export default function useToolingEffects(elementRef) {
  const { setActiveLesion } = useActiveLesion();
  const { setWindowingValues } = useWindowingStore();
  const { setMipValue } = useMipStore();
  const isHeatMapInitialized = useRef(false);

  const handleDrag = useCallback(() => {
    getAndSetWindowingValues(setWindowingValues);
  }, []);
  useEffect(() => {
    const defaultLesion = getDefaultLesion();
    const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
    if (renderingEngine && !isHeatMapInitialized.current) {
      applyDefaultColorMap();
      setActiveLesion(defaultLesion);
      renderingEngine.render();
      isHeatMapInitialized.current = true;
    }
  }, [getDefaultLesion()]);

  const handleCameraReset = useCallback(async () => {
    const viewport = getViewport() as Types.IVolumeViewport;
    if (viewport) {
      const windowingValues = calculateWindowingValues()
        || getDefaultWindowingValues(viewport?.getActors()[0]?.uid);
      setWindowingValues(windowingValues);

      const { currentSlice } = calculateSliceValues();
      const { isVesselReconstructionActive } = useIsVesselReconstructionActive.getState();
      // Update the slice value
      if (!isVesselReconstructionActive) {
        handleJumpToSlice(currentSlice);
      }
      // Set MIP values
      setMipDefaultValues(setMipValue);
    }
  }, [setWindowingValues]);

  const eventListeners = {
    [ToolEnums.Events.MOUSE_DRAG]: handleDrag,
    [ToolEnums.Events.TOUCH_DRAG]: handleDrag,
    [Enums.Events.CAMERA_RESET]: handleCameraReset,
  };

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    Object.entries(eventListeners).forEach(([event, handler]) => {
      element.addEventListener(event, handler);
    });

    // eslint-disable-next-line
    return () => {
      Object.entries(eventListeners).forEach(([event, handler]) => {
        element.removeEventListener(event, handler);
      });
    };
  }, []);

  return handleDrag;
}
