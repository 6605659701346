import { ActiveAxialOrientation,
  ActiveCoronalOrientation,
  ActiveSagittalOrientation,
  CrossIcon } from 'components/ui/icons';
import UISelectionMenu from 'components/ui/menus/selection/SelectionMenu';
import React from 'react';
import { ImageOrientation } from 'types';
import useImageOrientation from 'stores/imageOrientation';
import { ChangeOrientation } from 'utils/utilities';
import { NIFTI_ORIENTATIONS } from '../../constants';
import DefaultMenuCanvas from './DefaultMenuCanvas';

type OrientationMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const OrientationMenu: React.FC<OrientationMenuProps> = ({ setTopBar }) => {
  const { orientation, setOrientation } = useImageOrientation();

  const orientationOptions: Record<string, { title: string; icon: React.ReactNode }> = {
    axial: { title: NIFTI_ORIENTATIONS.AXIAL, icon: <ActiveAxialOrientation /> },
    sagittal: { title: NIFTI_ORIENTATIONS.SAGITTAL, icon: <ActiveSagittalOrientation /> },
    coronal: { title: NIFTI_ORIENTATIONS.CORONAL, icon: <ActiveCoronalOrientation /> },
  };

  const handleOrientationChange = (newOrientation: ImageOrientation) => {
    if (newOrientation !== orientation) {
      ChangeOrientation(newOrientation);
      setOrientation(newOrientation);
    }
    setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />);
  };

  const menuContent = Object.entries(orientationOptions).map(([imageOrientation, { title }]) => ({
    title,
    onClick: () => handleOrientationChange(imageOrientation as ImageOrientation),
  }));

  return (
    <UISelectionMenu
      menuContent={menuContent}
      activeIcon={orientationOptions[orientation].icon}
      activeItem={orientation}
      exitIcon={{
        icon: <CrossIcon />,
        onClick: () => setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />),
      }}
    />
  );
};

export default OrientationMenu;
