import { Enums, getRenderingEngine, setVolumesForViewports, Types, utilities } from '@cornerstonejs/core';
import { ToolGroupManager } from '@cornerstonejs/tools';
// eslint-disable-next-line import/no-extraneous-dependencies
import vtkVolumeProperty from '@kitware/vtk.js/Rendering/Core/VolumeProperty';
import React from 'react';
import { ImageOrientation, MipValue, ActiveVolumeData, WindowingValue } from 'types';
import { setTransferFunctionForVolumeActor } from 'utils/cornerstone/setup';
import { setMipDefaultValues } from 'utils/cornerstone/volumeUtils';
import useActiveLesion from 'stores/activeLesion';
import useSliderValues from 'stores/sliderValues';

import { DEFAULT_CTA_VALUES,
  DEFAULT_NCCT_VALUES,
  IMAGE_TYPES,
  RENDERING_ENGINE_ID,
  TOOL_GROUP_ID,
  VIEW_STATE,
  VIEWPORT_ID } from '../constants';
import { calculateSliceValues, calculateWindowingValues } from './cornerstone/tooling';
import { applyColorMaps, removeColorMaps } from './heatmaps/heatmaps';

export const getViewport = () => {
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
  return renderingEngine ? renderingEngine.getViewport(VIEWPORT_ID) : null;
};

export const updateVolumeWindow = async (windowWidth: number, windowCenter: number) => {
  const viewport = getViewport();
  const { actor } = viewport.getActors()[0];
  const property = actor.getProperty() as vtkVolumeProperty;
  const transferFunction = property.getRGBTransferFunction(0);
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);

  transferFunction.setMappingRange(windowCenter - windowWidth / 2, windowCenter + windowWidth / 2);
  renderingEngine?.render();
};

export const handleJumpToSlice = async (
  sliceIndex: number,
) => {
  const { setSliderValue } = useSliderValues.getState();
  const viewport = getViewport();
  await utilities.jumpToSlice(viewport!.element, {
    imageIndex: sliceIndex,
  });
  viewport?.render();

  setSliderValue(calculateSliceValues());
};

export const getDefaultWindowingValues = (volumeId: string): WindowingValue => {
  if (volumeId.toLowerCase().includes(IMAGE_TYPES.NCCT.toLowerCase())) {
    return DEFAULT_NCCT_VALUES;
  }
  return DEFAULT_CTA_VALUES;
};
export const resetFilters = (
  setWindowingValues: React.Dispatch<React.SetStateAction<WindowingValue>>,
  // eslint-disable-next-line
  setMipValue: (newValue: MipValue, displayValue: MipValue) => void,
) => {
  const viewport = getViewport();
  viewport?.setPan([0, 0]);
  const { windowWidth, windowCenter } = getDefaultWindowingValues(
    viewport.getActors()[0].referencedId,
  );
  viewport?.setZoom(1);
  setWindowingValues({ windowWidth, windowCenter });
  setMipDefaultValues(setMipValue);
  return updateVolumeWindow(windowWidth, windowCenter);
};

export const handleResize = (resetZoom?: boolean) => {
  const params = new URLSearchParams(window.location.search);
  const view = params.get('view');
  if (view === VIEW_STATE.TIMELINE) {
    return;
  }
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
  const viewport = getViewport();
  renderingEngine?.resize(true);
  viewport?.setPan([0, 0]);
  if (resetZoom) {
    viewport?.setZoom(1);
  }
};

export async function ChangeOrientation(
  orientation: ImageOrientation,
) {
  const engine = getRenderingEngine(RENDERING_ENGINE_ID);
  const { activeLesion } = useActiveLesion.getState();
  if (!engine) {
    throw new Error('Rendering engine not found');
  }

  const windowingValues = calculateWindowingValues();
  const viewportId = VIEWPORT_ID;
  const viewport = getViewport();
  if (!viewport) {
    throw new Error('Viewport not found');
  }

  const viewportInput: any = {
    viewportId,
    type: Enums.ViewportType.ORTHOGRAPHIC,
    element: viewport.element,
    defaultOptions: {
      orientation,
    },
  };

  const volumeActors = viewport.getActors();
  const volumeIds = volumeActors.map((actor) => actor.referencedId);

  const volumeSettings = volumeIds.map((volumeId) => ({
    volumeId,
    callback: async ({ volumeActor }) => {
      if (!volumeActor) {
        console.error('Volume actor not found');
        return;
      }
      // Use the imported function to set the transfer function
      setTransferFunctionForVolumeActor(
        volumeActor,
        volumeId,
        windowingValues.windowCenter,
        windowingValues.windowWidth,
      );
    },
  }));

  engine.setViewports([viewportInput]);

  try {
    await setVolumesForViewports(engine, volumeSettings, [viewportId]);

    const toolGroup = ToolGroupManager.getToolGroup(TOOL_GROUP_ID.DEFAULT_VOLUME);
    if (toolGroup && viewport) {
      toolGroup.addViewport(viewportId, engine.id);
    }
    if (activeLesion) {
      applyColorMaps(activeLesion);
    } else {
      removeColorMaps();
    }

    return true;
  } catch (error) {
    console.error('Failed to change orientation:', error);
    throw error;
  }
}

export const updateSlabThickness = (newThickness: MipValue) => {
  const viewport = getViewport() as Types.IVolumeViewport;
  if (!viewport) return;

  try {
    viewport.setProperties({
      slabThickness: newThickness,
      VOILUTFunction: Enums.VOILUTFunctionType.LINEAR,
    });

    viewport.setBlendMode(Enums.BlendModes.MAXIMUM_INTENSITY_BLEND);
    viewport.render();
  } catch (error) {
    console.error('Error updating slab thickness:', error);
  }
};

export function doesActiveVolumesHaveVesselData(activeVolumes: ActiveVolumeData): boolean {
  return Boolean(activeVolumes?.vessel && typeof activeVolumes.vessel === 'string');
}
