import { create } from 'zustand';
import { activateVesselReconstruction,
  deactivateVesselReconstruction } from 'utils/cornerstone/vesselReconstruction';
import useActiveVolumes from 'stores/activeVolumes';
import { doesActiveVolumesHaveVesselData } from 'utils/utilities';

interface VesselReconstructionState {
  isVesselReconstructionActive: boolean;
  toggleVesselReconstruction: () => Promise<void>;
}

const useIsVesselReconstructionActive = create<VesselReconstructionState>((set, get) => ({
  isVesselReconstructionActive: false,

  toggleVesselReconstruction: async () => {
    const { activeVolumesData } = useActiveVolumes.getState();
    if (!doesActiveVolumesHaveVesselData(activeVolumesData)) return;

    const { isVesselReconstructionActive } = get();

    // Depending on state, activate or deactivate
    if (!isVesselReconstructionActive) {
      activateVesselReconstruction(activeVolumesData!);
    } else {
      await deactivateVesselReconstruction(activeVolumesData!);
    }
    set({ isVesselReconstructionActive: !isVesselReconstructionActive });
  },
}));

// Auto-disable vessel reconstruction if volumes lose vessel data
useActiveVolumes.subscribe((state) => {
  const { isVesselReconstructionActive } = useIsVesselReconstructionActive.getState();
  if (!doesActiveVolumesHaveVesselData(state.activeVolumesData) && isVesselReconstructionActive) {
    useIsVesselReconstructionActive.setState({ isVesselReconstructionActive: false });
  }
});
export default useIsVesselReconstructionActive;
