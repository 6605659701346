import { create } from 'zustand';
import { ImageOrientation } from 'types';
import { NIFTI_ORIENTATIONS } from '../constants';

interface ImageOrientationState {
  orientation: ImageOrientation;
  // eslint-disable-next-line
  setOrientation: (orientation: ImageOrientation) => void;
}

const useImageOrientation = create<ImageOrientationState>((set) => ({
  orientation: NIFTI_ORIENTATIONS.AXIAL,
  setOrientation: (orientation) => set({ orientation }),
}));

export default useImageOrientation;
