import { useTheme } from '@mui/material/styles';
import { KeyboardArrowDown } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';
import './ResultAccordion.css';

type AccordionProps = {
  containerClassName?: string;
  headerClassName?: string;
  icon?: React.ReactNode;
  headerText: string;
  detailedComponent: React.ReactNode;
  expandable?: boolean;
};

export default function ResultAccordion({
  containerClassName,
  headerClassName,
  icon,
  headerText,
  detailedComponent,
  expandable = true,
}: AccordionProps) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpand = () => {
    if (expandable) {
      setExpanded((prev) => !prev);
    }
  };

  return (
    <Accordion
      slotProps={{
        transition: {
          timeout: 600,
        },
      }}
      disabled={!expandable}
      expanded={expanded}
      sx={{
        backgroundColor: 'background.default',
      }}
      className={containerClassName}
    >
      <AccordionSummary
        onClick={handleExpand}
        expandIcon={
          expandable ? (
            <KeyboardArrowDown style={{ color: theme.palette.text.primary }}/>
          ) : null
        }
      >
        <Typography className="accordion-header-content" sx={{ fontWeight: 'normal' }}>
          {icon && <span className="icon-container">{icon}</span>}
          {expandable && <>Results <span>|</span> </>}
          <span
            className={ headerClassName }
            style={!expandable ? { color: theme.palette.text.primary } : {}}
          >
            {headerText}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className='accordion-details'
        style={{ color: theme.palette.text.primary }}
      >
        {detailedComponent || <Typography sx={{ color: 'text.primary' }}>No details available.</Typography>}
      </AccordionDetails>
    </Accordion>
  );
}
