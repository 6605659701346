import React from 'react';
import { ProductResult } from 'types';
import './ExpandedTopNav.css';
import { useTheme } from '@mui/material/styles';

type ExpandedTopNavProps = {
  productResult: ProductResult;
  statuses: string[];
};

const getVisualProps = (processed: boolean, resultSummary: string) => [
  {
    condition: processed && resultSummary.includes('Suspected'),
    headerClass: 'results-accordion-header-warning',
  },
  {
    condition: processed,
    headerClass: 'results-accordion-header-complete',
  },
  {
    condition: true, // default case
    headerClass: '',
  },
].find((item) => item.condition);

export default function ExpandedTopNav({ productResult, statuses }: ExpandedTopNavProps) {
  const theme = useTheme();
  const hasProcessed = statuses.some((status) => status === 'Processed');
  const hasProcessing = statuses.some((status) => status === 'Processing');
  let resultSummary: string;

  if (hasProcessed) {
    resultSummary = productResult.summary;
  } else {
    resultSummary = hasProcessing ? 'Result Processing' : 'No Results';
  }

  const visualProps = getVisualProps(hasProcessed, resultSummary);
  const spanClassName = visualProps?.headerClass;

  return (
    <div className='expanded-top-nav-container'>
      <span
        className={ spanClassName }
        style={{ color: theme.palette.text.primary }}
      >
        {resultSummary}
      </span>
    </div>
  );
}
