import { create } from 'zustand';
import { updateSlabThickness } from 'utils/utilities';
import { MipValue } from '../types';

interface MipStore {
  mipValue: MipValue;
  // eslint-disable-next-line
  setMipValue: (newValue: MipValue, displayValue: MipValue) => void;
}

const useMipStore = create<MipStore>((set) => ({
  mipValue: 0,
  setMipValue: (newValue, displayValue) => {
    set({ mipValue: displayValue });
    updateSlabThickness(newValue);
  },
}));

export default useMipStore;
