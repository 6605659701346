import Spinner from 'components/ui/spinner/Spinner';
import useToolingEffects from 'hooks/useToolingEffects';
import React, { useEffect, useRef } from 'react';
import { loadNewVolume } from 'utils/cornerstone/setup';
import './Canvas.css';
import { ActiveVolumeData } from 'types';

type CanvasProps = {
  initialVolumeData: ActiveVolumeData;
};
export default function Canvas({ initialVolumeData }: CanvasProps) {
  const elementRef = useRef<HTMLDivElement>(null);

  useToolingEffects(elementRef);
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    /*
      This prevent you from zooming using your native browser/device
      functionality and allows for cornerstone zoom to be activated instead
    */
    elementRef.current?.addEventListener('touchmove', preventDefault);
    elementRef.current?.addEventListener('contextmenu', preventDefault);

    const volumeIds = {
      image: initialVolumeData.image,
      lesions: initialVolumeData.lesions,
    };
    loadNewVolume(volumeIds, elementRef);
    return () => {
      elementRef.current?.removeEventListener('touchmove', preventDefault);
      elementRef.current?.removeEventListener('contextmenu', preventDefault);
    };
  }, []);

  return (
    <div className='nifti-image' ref={elementRef}>
      <Spinner />
    </div>
  );
}
