import { create } from 'zustand';
import { SliderValue } from 'types';

type sliderValuesStore = {
  sliderValue: SliderValue;
  // eslint-disable-next-line
  setSliderValue: (sliderValue: SliderValue) => void;
};

const useSliderValues = create<sliderValuesStore>((set) => ({
  sliderValue: { currentSlice: 0, maxSlice: 0 },
  setSliderValue: (sliderValue: SliderValue) => set({ sliderValue }),
}));

export default useSliderValues;
