import React from 'react';

const WarningIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.865 9.00011L6.86503 2.00011C6.77781 1.84621 6.65133 1.71821 6.49849 1.62915C6.34565 1.54009 6.17192 1.49316 5.99503 1.49316C5.81813 1.49316 5.6444 1.54009 5.49156 1.62915C5.33872 1.71821 5.21224 1.84621 5.12503 2.00011L1.12503 9.00011C1.03687 9.15279 0.99064 9.32606 0.99103 9.50237C0.991421 9.67867 1.03841 9.85174 1.12725 10.004C1.21608 10.1563 1.3436 10.2824 1.49687 10.3695C1.65015 10.4566 1.82373 10.5017 2.00003 10.5001H10C10.1755 10.4999 10.3478 10.4536 10.4997 10.3658C10.6515 10.2779 10.7776 10.1517 10.8653 9.99967C10.9529 9.84768 10.999 9.67531 10.999 9.49986C10.999 9.32441 10.9528 9.15206 10.865 9.00011Z" stroke="#B83F3F" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M6 4.5V6.5" stroke="#B83F3F" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M6 8.5H6.005" stroke="#B83F3F" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default WarningIcon;
