import ViewerMenu from 'components/ViewerMenu/ViewerMenu';
import LesionMenu from 'components/menuInputs/LesionMenu';
import Canvas from 'components/ui/canvas/Canvas';
import UISlider from 'components/ui/slider/Slider';
import useView from 'hooks/useView';
import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { ActiveVolumeData } from 'types';
import { handleJumpToSlice, handleResize } from 'utils/utilities';
import useSliderValues from 'stores/sliderValues';
import ViewerFooter from 'components/viewerFooter/ViewerFooter';
import useIsVesselReconstructionActive from 'stores/useVesselReconstruction';
import { VIEW_STATE } from '../../constants';
import './Viewer.css';

type ViewerProps = {
  initialVolumeData: ActiveVolumeData;
  showLesionMenu: boolean;
};

export default function Viewer({
  initialVolumeData,
  showLesionMenu,
}: ViewerProps) {
  const [activeView] = useView();
  // TODO update to use native cornerstone event
  const handleDeviceOrientationChange = debounce(() => {
    handleResize(true);
  }, 50);

  const { isVesselReconstructionActive } = useIsVesselReconstructionActive();
  useEffect(() => {
    window.addEventListener('resize', () => handleResize());
    window.addEventListener('orientationchange', handleDeviceOrientationChange);
    return () => {
      window.removeEventListener('resize', () => handleResize());
      window.removeEventListener('orientationchange', handleDeviceOrientationChange);
    };
  }, []);
  const { sliderValue } = useSliderValues();
  const hasRendered = sliderValue.maxSlice > 0;
  useEffect(() => {
    const shouldChangeCanvas = activeView === VIEW_STATE.EXPANDED || activeView === VIEW_STATE.CASE;
    if (shouldChangeCanvas) {
      handleResize(true);
    }
  }, [activeView]);

  const handleSliderChange = async (value: number) => {
    await handleJumpToSlice(value);
  };

  // Flexbox with 2 items, column, space between for top/bottom canvas separation
  return (
    <div className={`nifti-viewer ${activeView === VIEW_STATE.TIMELINE ? 'none' : ''}`}>
      <Canvas initialVolumeData={initialVolumeData} />
      <div className='menu-spacing'>
        <div className='top-menu-container'>
          <ViewerMenu isVesselReconstructionActive={isVesselReconstructionActive} />
        </div>
        <div className={`bottom-menu-container ${showLesionMenu ? '' : 'add-margin-bottom'}`}>
          <ViewerFooter
            hasRendered={hasRendered}
            sliderValue={sliderValue}
            isVesselReconstructionActive={isVesselReconstructionActive}
          />
          {!isVesselReconstructionActive && (
            <>
              <UISlider value={sliderValue} onSliderChange={handleSliderChange} useArrows={true} />
              {showLesionMenu && <LesionMenu />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
