import { ToolGroupManager, Types } from '@cornerstonejs/tools';

import * as cornerstoneTools from '@cornerstonejs/tools';
import { getViewport } from 'utils/utilities';
import { toolGroups } from './toolConfig';
import { RENDERING_ENGINE_ID, TOOL_GROUP_ID, VIEWPORT_ID } from '../../../constants';

type ToolGroup = Types.IToolGroup;

function activateToolGroup(toolGroupId: TOOL_GROUP_ID): ToolGroup {
  const existingToolGroup = ToolGroupManager.getToolGroup(toolGroupId);
  if (existingToolGroup) return existingToolGroup;

  // Clean up any existing tool groups
  ToolGroupManager.getAllToolGroups().forEach((group) => {
    const currentViewportId = getViewport()?.id || VIEWPORT_ID;
    if (group.getViewportIds().some((viewportId) => viewportId === currentViewportId)) {
      group.removeViewports(RENDERING_ENGINE_ID, currentViewportId);
      ToolGroupManager.destroyToolGroup(group.id);
    }
  });

  const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);
  const { tools, bindings, settings } = toolGroups[toolGroupId];

  // Single pass using array methods
  tools.forEach((Tool) => {
    const { toolName } = Tool;
    const toolSettings = settings?.[toolName] || {};
    const toolBindings = bindings[toolName] || [];

    if (!cornerstoneTools.state.tools[toolName]) {
      cornerstoneTools.addTool(Tool);
    }

    // make sure to update the tool settings
    toolGroup.addTool(toolName, toolSettings);

    // activate all tools for a given config and apply bindings where available
    toolGroup.setToolActive(toolName, {
      bindings: toolBindings,
    });
  });

  // Add to current viewport after setup
  const currentViewportId = getViewport()?.id || VIEWPORT_ID;
  toolGroup.addViewport(currentViewportId, RENDERING_ENGINE_ID);

  return toolGroup;
}

export default activateToolGroup;
