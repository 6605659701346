import { Lesions } from 'types';
import { create } from 'zustand';

interface ActiveLesionStore {
  activeLesion: Lesions | null;
  // eslint-disable-next-line
  setActiveLesion: (lesion: Lesions) => void;
}

const useActiveLesion = create<ActiveLesionStore>((set) => ({
  activeLesion: null,
  setActiveLesion: (lesion: Lesions) => set({ activeLesion: lesion }),
}));

export default useActiveLesion;
