import React from 'react';

const ProcessingIcon: React.JSX.Element = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.5"
      cy="7"
      r="5.85"
      fill="none"
      stroke="url(#gradient)"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="gradient"
        x1="7" y1="0"
        x2="7" y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="white" />
        <stop offset="100%" stopColor="#1A1A1A" />
      </linearGradient>
    </defs>
  </svg>
);

export default ProcessingIcon;
