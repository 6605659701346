import { eventTarget, Enums as CoreEnums } from '@cornerstonejs/core';
import { Enums as NiftiEnums } from '@cornerstonejs/nifti-volume-loader';
import { useCallback, useEffect, useRef, useState } from 'react';

/* Hook to track NIFTI volume loading progress */
export default function useLoadingProgress() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const loadStartTime = useRef(0);

  // Constants
  const EXCLUDED_VOLUMES = ['clot', 'hemo', 'acute'];
  function isExcludedVolume(uniqueVolumeIdentifier: string) {
    // eslint-disable-next-line max-len
    return EXCLUDED_VOLUMES.some((excluded) => uniqueVolumeIdentifier.toLowerCase().includes(excluded));
  }

  const handleProgress = useCallback((event: any) => {
    // In cornerstone 3.4.0, event.detail.data has either volumeId or url, so we use the defined one
    const { volumeId, url, loaded, total } = event.detail.data;
    const uniqueVolumeIdentifier = volumeId || url;
    if (isExcludedVolume(uniqueVolumeIdentifier)) {
      return;
    }

    setIsLoading(true);
    if (loadStartTime.current === 0) {
      loadStartTime.current = Date.now();
    }

    setLoadingProgress(Math.round((loaded / total) * 100));
  }, []);

  // Unified handler for loaded events
  const handleLoaded = useCallback((event: any) => {
    const eventDetail = event.detail;

    // Extract volume identifier from event detail, supporting both top-level and nested structure
    const uniqueVolumeIdentifier = typeof eventDetail.volumeId === 'string'
      ? eventDetail.volumeId
      : eventDetail.data.volumeId;

    if (isExcludedVolume(uniqueVolumeIdentifier)) {
      return;
    }

    const resetState = () => {
      setIsLoading(false);
      setLoadingProgress(0);
      loadStartTime.current = 0;
    };

    resetState();
  }, []);

  useEffect(() => {
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_PROGRESS, handleProgress);
    eventTarget.addEventListener(CoreEnums.Events.IMAGE_VOLUME_LOADING_COMPLETED, handleLoaded);
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleLoaded);

    return () => {
      eventTarget.removeEventListener(NiftiEnums.Events.NIFTI_VOLUME_PROGRESS, handleProgress);
      // eslint-disable-next-line max-len
      eventTarget.removeEventListener(CoreEnums.Events.IMAGE_VOLUME_LOADING_COMPLETED, handleLoaded);
      eventTarget.removeEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleLoaded);
    };
  }, []);

  return { isLoading, loadingProgress };
}
