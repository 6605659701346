import { authentication } from '@microsoft/teams-js';
import { GetCaseDataRequestBody } from 'types';
import { IS_IN_DEV } from '../constants';
import data from '../mockups/example_diag_response.json';

export default async function getCaseData(
  token: string,
  requestBody: GetCaseDataRequestBody,
  env: string,
) {
  try {
    const endpoint = process.env[`REACT_APP_STROKE_ENDPOINT_${env}`];
    const response = await fetch(`${endpoint}/ms_teams_viewer/v2/viewer_data`, {
      method: 'POST',
      headers: {
        key: '1234',
        token,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(requestBody),
    });

    return response;
  } catch (error) {
    console.error('Error fetching case data:', error);
    throw error;
  }
}

export async function requestAccess() {
  try {
    const params = new URLSearchParams(window.location.search);
    const diagnosticId = params.get('diagnostic_id');
    const env = params.get('env');
    const product = params.get('product');
    const caseId = params.get('case_id');
    const endpoint = process.env[`REACT_APP_STROKE_ENDPOINT_${env}`];
    const requestBody = {
      case_id: caseId,
      product,
      diag_id: diagnosticId,
    };
    authentication.getAuthToken({
      successCallback: async (token) => {
        const response = await fetch(`${endpoint}/ms_teams_viewer/request_access`, {
          method: 'POST',
          headers: {
            key: '1234',
            token,
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify(
            requestBody,
          ),
        });
        return response.json();
      },
      failureCallback: (err) => {
        console.error('Error requesting access:', err);
      },
    });
  } catch (error) {
    console.error('Error requesting access:', error);
  }
}

// Separate function to handle MS Teams authentication
async function getMsTeamsAuthToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    authentication.getAuthToken({
      successCallback: (token) => resolve(token),
      failureCallback: (error) => reject(error),
    });
  });
}

export async function requestTimelineData() {
  try {
    const params = new URLSearchParams(window.location.search);
    const env = params.get('env');
    const diagnosticId = params.get('diagnostic_id');
    const product = params.get('product');
    const caseId = params.get('case_id');
    const requestBody = {
      case_id: caseId,
      product,
      diag_id: diagnosticId,
    };

    // Get the appropriate token based on environment
    let authToken: string;
    if (IS_IN_DEV) {
      authToken = process.env.REACT_APP_MICROSOFT_DEV_TOKEN ?? '';
      if (!authToken) {
        return data; // Return mock data if no dev token
      }
    } else {
      authToken = await getMsTeamsAuthToken();
    }

    // Fetch timeline data with the obtained token
    const endpoint = process.env[`REACT_APP_STROKE_ENDPOINT_${env}`];
    const response = await fetch(`${endpoint}/ms_teams_viewer/timeline_data`, {
      method: 'POST',
      headers: {
        key: '1234',
        token: authToken,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(requestBody),
    });

    return await response.json();
  } catch (error) {
    console.error('Error requesting timeline data:', error);
    throw error;
  }
}
