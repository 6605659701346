import React from 'react';
import { ImageAnalysisEvent } from 'types';
import { formatIsoToHHMM } from 'utils/time';
import CtaTimelineIcon from 'components/ui/icons/CtaTimeline';
import NcctTimelineIcon from 'components/ui/icons/NcctTimeline';
import ProcessingIcon from 'components/ui/icons/Processing';
import './ImageAnalysisCard.css';

interface ImageAnalysisCardProps {
  timelineObject: ImageAnalysisEvent;
}

const ImageAnalysisCard: React.FC<ImageAnalysisCardProps> = ({ timelineObject }) => {
  const {
    processingStatus,
    analysis_result: analysisResult,
    event_datetime: eventDatetime,
    notification_time: receptionTime,
    image_type: imageType,
    volumes,
  } = timelineObject;

  const isSuspected = analysisResult?.includes('Suspected') ?? false;

  const ImageIcon = imageType === 'CTA' ? CtaTimelineIcon : NcctTimelineIcon;

  const renderProcessingStatusAndResult = () => (
    <div className="headline-result">
      <span className="status-container">
        {processingStatus === 'Processing' && (
          ProcessingIcon
        )}
        {processingStatus}
      </span>
      {processingStatus === 'Processed' && analysisResult && (
        <>
          {' - '}
          <span className={isSuspected ? 'suspected-text' : 'blue-text-image-analysis'}>
            {analysisResult}
          </span>
        </>
      )}
    </div>
  );

  const renderVolumeDetails = () => {
    if (!volumes) return null;

    return Object.entries(volumes).map(([key, value]) => (
      <span className="opacity-zero-point-five" key={key}>
        {key.split('_').slice(1).join(' ')}: {value}
      </span>
    ));
  };

  const renderReceptionTime = () => (
    <div className="third-column opacity-zero-point-five">
      {formatIsoToHHMM(receptionTime)}
    </div>
  );

  return (
    <div className="timeline-card-container">
      <div className="timeline-card-row">
        <div className="first-column">
          <div className="timeline-icon"><ImageIcon /></div>
        </div>
        <div className="second-column">{imageType}</div>
        <div className="third-column">{formatIsoToHHMM(eventDatetime)}</div>
      </div>

      <div className="timeline-card-row">
        <div className="first-column" />
        <div className="second-column image-analysis-info-container">
          {renderProcessingStatusAndResult()}
          {processingStatus === 'Processed' && renderVolumeDetails()}
        </div>
        {processingStatus === 'Processed' && renderReceptionTime()}
      </div>
    </div>
  );
};

export default ImageAnalysisCard;
