import React from 'react';
import { SliderValue, WindowingValue } from 'types';
import { getViewport } from 'utils/utilities';
// eslint-disable-next-line import/no-extraneous-dependencies
import vtkVolumeProperty from '@kitware/vtk.js/Rendering/Core/VolumeProperty';

// Function to calculate windowing values
export const calculateWindowingValues = (): WindowingValue => {
  const viewport = getViewport();
  const actor = viewport?.getActors()[0]?.actor;
  const property = actor.getProperty() as vtkVolumeProperty;
  const transferFunction = property.getRGBTransferFunction(0);
  const [currentMin, currentMax] = transferFunction.getMappingRange();
  return {
    windowWidth: currentMax - currentMin,
    windowCenter: (currentMax + currentMin) / 2,
  };
};

// Function to set windowing values using the calculated values
export const getAndSetWindowingValues = (
  setWindowingValues: React.Dispatch<React.SetStateAction<WindowingValue>>,
) => {
  const windowingValues = calculateWindowingValues();
  setWindowingValues(windowingValues);
};

export const calculateSliceValues = (): SliderValue | null => {
  try {
    const viewport = getViewport();
    const maxSlice = viewport?.getNumberOfSlices();
    const currentSlice = viewport.getSliceIndex();
    return {
      currentSlice,
      maxSlice,
    };
  } catch (error) {
    return null;
  }
};
