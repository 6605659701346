import DefaultMenuCanvas from 'components/menuInputs/DefaultMenuCanvas';
import useMipStore from 'stores/mip';
import useWindowingStore from 'stores/windowing';
import React, { useEffect, useState } from 'react';
import useActiveVolumes from 'stores/activeVolumes';
import { IMAGE_TYPES } from '../../constants';
import './ViewerMenu.css';

type ViewerMenuProps = {
  isVesselReconstructionActive: boolean;
};
export default function ViewerMenu({ isVesselReconstructionActive }: ViewerMenuProps) {
  const [topBar, setTopBar] = useState<React.ReactNode>(null);
  const { mipValue } = useMipStore();
  const { windowingValues } = useWindowingStore();

  const isCtp = useActiveVolumes().activeVolumesData?.imageType === IMAGE_TYPES.CTP;
  const shouldDisplayStats = !isVesselReconstructionActive && !isCtp;
  useEffect(() => {
    setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />);
  }, []);

  return (
    <>
      {topBar}
      {shouldDisplayStats && (
        <>
          <div className='canvas-stats'>
            WW/WL: {windowingValues.windowWidth.toFixed(0)}
            /
            {windowingValues.windowCenter.toFixed(0)}
          </div>
          <div className='canvas-stats'>MIP: {mipValue.toFixed(2)} mm</div>
        </>
      )}
    </>
  );
}
