import React from 'react';
import { handleDropdownItemClick } from 'components/imagedropdown/ImageDropdown';
import useActiveVolumes from 'stores/activeVolumes';
import { shouldDisplayLesionMenu } from 'utils/heatmaps/heatmaps';
import { formatIsoToMonthDayHourMinute12h } from 'utils/time';
import { ImageInfo, ImageType, Images, NiftiUrls, Product, formattedImageData } from 'types';
import useIsVesselReconstructionActive from 'stores/useVesselReconstruction';
import useActiveLesion from 'stores/activeLesion';
/**
 * Formats an array of images for use in a dropdown component.
 *
 * @param images - The images object for each product (e.g., ce, fda, res).
 * @param selectedProduct - The selected product type.
 * @param niftiUrls - The Nifti URLs object with main image URLs and lesion URLs.
 * @param selectedImage - The string ID of the currently selected image.
 * @param setShowLesionMenu - Setter to show or hide the lesion menu based on the chosen image.
 * @returns An array of formatted items suitable for a dropdown (label, value, onClick, etc.).
 */
export default function formatImageData(
  images: Images,
  selectedProduct: Product,
  niftiUrls: NiftiUrls,
  selectedImage: string,
  setShowLesionMenu: React.Dispatch<React.SetStateAction<boolean>>,
): formattedImageData[] {
  const { setActiveLesion } = useActiveLesion();
  const { setActiveVolumeData } = useActiveVolumes();
  const {
    isVesselReconstructionActive,
    toggleVesselReconstruction,
  } = useIsVesselReconstructionActive();

  /**
   * Helper to build the volume IDs (main image, lesion array, optional vessel)
   * for a given image ID and product.
   */
  const buildVolumeIds = (imageId: string, imageType: ImageType) => {
    const niftiData = niftiUrls[imageId];
    if (!niftiData) return null;

    // E.g. niftiData.lesions = { ce: { clot: "...", hemo: "..." } }
    const productLesions = niftiData.lesions?.[selectedProduct];
    return {
      image: niftiData.image ?? null,
      lesions: productLesions ? Object.values(productLesions).map(String) : [],
      vessel: niftiData.vessel ?? null,
      imageType,
    };
  };

  // Map over each image for the selected product, formatting it into
  // the shape required by the dropdown: (label, value, onClick, etc.).
  return Object.entries(images[selectedProduct])
    .map(([imageId, imageInfo]: [string, ImageInfo]) => {
      const volumeData = buildVolumeIds(imageId, imageInfo.type);
      const isLoadable = !!volumeData?.image;
      const { processingStatus } = imageInfo;
      return {
        image: volumeData?.image,
        lesions: volumeData?.lesions || [],
        vessel: volumeData?.vessel || null,
        selectedImage: parseInt(selectedImage, 10) === parseInt(imageId, 10),
        label: `${imageInfo.type} ${formatIsoToMonthDayHourMinute12h(imageInfo.datetime)}`,
        value: volumeData?.image || '',
        disabled: !isLoadable,
        onClick: isLoadable
          ? async () => {
            // Determine whether we should show the lesion menu for this image type
            setShowLesionMenu(shouldDisplayLesionMenu(selectedProduct, imageInfo.type));

            // Finally, load the new volume, set active lesion, etc.
            handleDropdownItemClick(
              volumeData,
              setActiveLesion,
              isVesselReconstructionActive,
              toggleVesselReconstruction,
              setActiveVolumeData,
            );
          }
          : null,
        processingStatus,
      };
    });
}
