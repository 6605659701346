import React, { useCallback, useEffect, useState } from 'react';
import { VIEW_STATE } from '../constants';
import { ViewValue } from '../types';

type UseViewReturn = [ViewValue, React.Dispatch<React.SetStateAction<ViewValue>>];

export default function useView(): UseViewReturn {
  const paramKey = 'view';
  const getActiveView = () => {
    const params = new URLSearchParams(window.location.search);
    const viewState = params.get(paramKey);
    if (viewState?.toUpperCase() in VIEW_STATE) {
      return viewState as ViewValue;
    }
    return VIEW_STATE.CASE;
  };
  const [activeView, setActiveView] = useState<ViewValue>(getActiveView());

  const updateView = useCallback((newView: ViewValue) => {
    const url = new URL(window.location.href);
    url.searchParams.set(paramKey, newView);
    window.history.replaceState({}, '', url.toString());
    window.dispatchEvent(new Event('popstate'));
  }, []);

  const handleUrlChange = useCallback(() => {
    const viewState = getActiveView();
    setActiveView(viewState as ViewValue);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', handleUrlChange);
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);

  return [activeView, updateView];
}
