import { Enums,
  RenderingEngine,
  cache,
  init as csInit,
  getRenderingEngine,
  setVolumesForViewports,
  volumeLoader,
  setPreferSizeOverAccuracy,
  imageLoader } from '@cornerstonejs/core';
import { cornerstoneNiftiImageLoader,
  createNiftiImageIdsAndCacheMetadata } from '@cornerstonejs/nifti-volume-loader';
import * as cornerstoneTools from '@cornerstonejs/tools';
import { RENDERING_ENGINE_ID, TOOL_GROUP_ID, VIEWPORT_ID } from '../../constants';
import { ActiveVolumeData } from '../../types';
import { getDefaultWindowingValues } from '../utilities';
import activateToolGroup from './tooling/toolActivation';

const { init: csTools3dInit } = cornerstoneTools;

export function setTransferFunctionForVolumeActor(
  volumeActor: any,
  volumeId: string,
  windowCenter?: number,
  windowWidth?: number,
) {
  const volumeProperty = volumeActor.getProperty();
  const transferFunction = volumeProperty.getRGBTransferFunction(0);
  if (!transferFunction?.setMappingRange) {
    console.error('Transfer function not available');
    return;
  }

  const defaults = getDefaultWindowingValues(volumeId);
  const effectiveCenter = windowCenter ?? defaults.windowCenter;
  const effectiveWidth = windowWidth ?? defaults.windowWidth;

  const lower = effectiveCenter - effectiveWidth / 2;
  const upper = effectiveCenter + effectiveWidth / 2;

  transferFunction.setMappingRange(lower, upper);
}

export async function setupVolumes(
  volumeData: { main: string; lesions: string[] },
  callback: any,
) {
  try {
    const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);

    // Process main volume
    const mainImageIds = await createNiftiImageIdsAndCacheMetadata({
      url: volumeData.main,
    });
    const mainVolumeId = `nifti:${volumeData.main}`;
    const mainVolume = await volumeLoader.createAndCacheVolume(mainVolumeId, {
      imageIds: mainImageIds,
    });
    await mainVolume.load();

    // Process lesion volumes
    const lesionVolumeIds = await Promise.all(
      volumeData.lesions.map(async (lesionUrl) => {
        const lesionImageIds = await createNiftiImageIdsAndCacheMetadata({
          url: lesionUrl,
        });
        const lesionVolumeId = `nifti:${lesionUrl}`;
        const lesionVolume = await volumeLoader.createAndCacheVolume(
          lesionVolumeId,
          { imageIds: lesionImageIds },
        );
        await lesionVolume.load();
        return lesionVolumeId;
      }),
    );

    // Configure viewports
    const volumeSettings = [
      { volumeId: mainVolumeId, callback },
      ...lesionVolumeIds.map((id) => ({ volumeId: id, callback })),
    ];

    await setVolumesForViewports(renderingEngine!, volumeSettings, [VIEWPORT_ID]);
  } catch (error) {
    console.error('Error setting up volumes:', error);
  }
}

const initializeCornerstoneLoader = async () => {
  setPreferSizeOverAccuracy(true);

  await csInit();
  await csTools3dInit();

  const cornerstoneNiftiImageLoaderWrapper = (imageId: string) => {
    const imageLoadObject = cornerstoneNiftiImageLoader(imageId);
    return {
      ...imageLoadObject,
      promise: imageLoadObject.promise.then((image) => image as unknown as Record<string, unknown>),
    };
  };

  imageLoader.registerImageLoader('nifti', cornerstoneNiftiImageLoaderWrapper);

  const renderingEngine = new RenderingEngine(RENDERING_ENGINE_ID);
  return renderingEngine;
};

export async function loadNewVolume(volumeData: Partial<ActiveVolumeData>, elementRef?: any) {
  let existingRenderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
  if (!existingRenderingEngine) {
    existingRenderingEngine = await initializeCornerstoneLoader();
    const element = elementRef.current;

    if (element) {
      const viewportInput = {
        viewportId: VIEWPORT_ID,
        type: Enums.ViewportType.ORTHOGRAPHIC,
        element,
        defaultOptions: {
          orientation: Enums.OrientationAxis.AXIAL,
        },
      };
      existingRenderingEngine.setViewports([viewportInput]);
    }
  }

  // The code below addresses an issue with browser tab reloads before volume display:
  // - Sets a maximum cache size of 800 MB, suitable for low/mid-tier mobile devices.
  // - Removes least recently used volumes to free up memory as needed.
  // - Based on testing, a 40 MB volume can consume about 250 MB of memory (September 2024).
  const eightHundredMB = 800 * 1024 * 1024;
  cache.setMaxCacheSize(eightHundredMB);
  cache.decacheIfNecessaryUntilBytesAvailable(eightHundredMB, [volumeData.image]);
  activateToolGroup(TOOL_GROUP_ID.DEFAULT_VOLUME);

  // Validate input
  if (!volumeData.image) {
    throw new Error('Main volume URL is required');
  }

  // Execute volume setup with callback
  await setupVolumes(
    {
      main: volumeData.image,
      lesions: volumeData.lesions || [],
    },
    ({ volumeActor }) => setTransferFunctionForVolumeActor(
      volumeActor,
      `nifti:${volumeData.image}`,
    ),
  );
}
