import { WindowingValue } from 'types';
import { create } from 'zustand';
import React from 'react';

interface WindowingStore {
  windowingValues: WindowingValue;
  setWindowingValues: React.Dispatch<React.SetStateAction<WindowingValue>>;
}

const useWindowingStore = create<WindowingStore>((set) => ({
  windowingValues: {
    windowWidth: 0,
    windowCenter: 0,
  },
  setWindowingValues: (windowingValues: WindowingValue) => set({ windowingValues }),
}));

export default useWindowingStore;
