import React from 'react';

export default function CtaTimelineIcon() {
  return (
    <svg width='35' height='66' viewBox='0 0 35 66' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='35' height='35' rx='17.5' fill='#7486AE' />
      <path
        d='M17.5002 12.9634V17.3985V21.9419C17.5002 22.4827 18.1493 23.1318 19.0147 23.1318C19.8801 23.1318 20.4209 22.2664 20.5291 21.6173C22.606 21.4443 23.1252 19.6702 23.1252 18.8048C23.1252 16.9659 22.6926 16.6414 21.5026 16.6414C22.7142 16.2087 22.7286 15.0188 22.5844 14.4779C22.1517 12.9634 20.7454 13.288 20.4209 13.288C20.3127 12.9635 20.2046 11.9899 19.0147 11.8817C18.0627 11.7952 17.6084 12.5668 17.5002 12.9634Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5002 12.9634V17.3985V21.9419C17.5002 22.4827 16.8512 23.1318 15.9858 23.1318C15.1204 23.1318 14.5796 22.2664 14.4714 21.6173C12.3945 21.4443 11.8752 19.6702 11.8752 18.8048C11.8752 16.9659 12.3079 16.6414 13.4978 16.6414C12.2863 16.2087 12.2719 15.0188 12.4161 14.4779C12.8488 12.9634 14.2551 13.288 14.5796 13.288C14.6877 12.9635 14.7959 11.9899 15.9858 11.8817C16.9377 11.7952 17.3921 12.5668 17.5002 12.9634Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.0624 20.3123C19.0623 19.3748 18.7842 13.2089 19.5193 14.5332C19.8769 15.1777 20.3126 15.3123 20.0092 16.3872C19.6876 18.1248 21.0634 17.8866 20.9376 19.0623C20.8118 20.2381 19.0626 21.2499 19.0624 20.3123Z'
        fill='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.9422 20.3123C15.9424 19.3748 16.2204 13.2089 15.4854 14.5332C15.1277 15.1777 14.692 15.3123 14.9954 16.3872C15.3171 18.1248 13.9412 17.8866 14.067 19.0623C14.1929 20.2381 15.942 21.2499 15.9422 20.3123Z'
        fill='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line x1='17' y1='48' x2='17' y2='35' stroke='white' />
      <ellipse cx='17' cy='48.4688' rx='3.5' ry='3.50009' fill='#A6B2CB' />
    </svg>
  );
}
