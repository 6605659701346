import { Product } from 'types';
import { create } from 'zustand';

interface ProductStore {
  product: Product | null;
  // eslint-disable-next-line
  setProduct: (product: Product) => void;
}

const useProduct = create<ProductStore>((set) => ({
  product: null,
  setProduct: (product: Product) => set({ product }),
}));

export default useProduct;
