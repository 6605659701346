import ResultsAccordion from 'components/results/accordion/ResultAccordion';
import ResultsTable from 'components/results/table/ResultTable';
import { ExclamationBlueIcon, ExclamationRedIcon } from 'components/ui/icons';
import ProcessingIcon from 'components/ui/icons/Processing';
import React from 'react';
import { ProductResult } from 'types';

type ResultsProps = {
  productResult: ProductResult;
  statuses: string[];
};

const getVisualProps = (accordionExpandable: boolean, headerText: string) => [
  {
    condition: accordionExpandable && headerText.includes('Suspected'),
    containerClass: 'results-accordion-container-warning',
    headerClass: 'results-accordion-header-warning',
    icon: ExclamationRedIcon,
  },
  {
    condition: accordionExpandable,
    containerClass: 'results-accordion-container',
    headerClass: 'results-accordion-header-complete',
    icon: ExclamationBlueIcon,
  },
  {
    condition: headerText === 'Result Processing',
    containerClass: 'results-accordion-container-disabled',
    headerClass: 'results-accordion-header-disabled',
    icon: ProcessingIcon,
  },
  {
    condition: true, // default case
    containerClass: 'results-accordion-container-disabled',
    headerClass: 'results-accordion-header-disabled',
    icon: null,
  },
].find((item) => item.condition);

export default function Results({ productResult, statuses }: ResultsProps) {
  const hasProcessed = statuses.some((status) => status === 'Processed');
  const hasProcessing = statuses.some((status) => status === 'Processing');

  let headerText: string;
  let accordionExpandable: boolean;

  const { summary, ...results } = productResult || { summary: '' };

  if (hasProcessed) {
    headerText = summary;
    accordionExpandable = true;
  } else {
    headerText = hasProcessing ? 'Result Processing' : 'No Results';
    accordionExpandable = false;
  }

  // Define the order we want to display the results
  const order = ['ncct_lvo', 'ncct_ischemia_volume', 'ncct_ich', 'ncct_ich_volume', 'cta_lvo'];

  const tableData = Object.entries(results)
    .sort(([keyA], [keyB]) => order.indexOf(keyA) - order.indexOf(keyB))
    .map(([key, value]) => ({
      label: key.replace(/_/g, ' ').toUpperCase(),
      value: value.agg_result,
    }));

  const visualProps = getVisualProps(accordionExpandable, headerText);
  const containerClassName = visualProps?.containerClass;
  const headerTextClassName = visualProps?.headerClass;
  const iconComponent = visualProps?.icon;

  return (
    <div>
      <ResultsAccordion
        containerClassName={containerClassName}
        headerClassName={headerTextClassName}
        icon={iconComponent}
        headerText={ headerText }
        detailedComponent={<ResultsTable data={tableData} />}
        expandable={accordionExpandable}
      />
    </div>
  );
}
