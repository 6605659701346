import { Enums,
  getRenderingEngine,
  setVolumesForViewports,
  volumeLoader } from '@cornerstonejs/core';
import useImageOrientation from 'stores/imageOrientation';
import { loadNewVolume } from 'utils/cornerstone/setup';
import activateToolGroup from 'utils/cornerstone/tooling/toolActivation';
import { ChangeOrientation, getViewport } from 'utils/utilities';
import { ActiveVolumeData } from 'types';
import { createNiftiImageIdsAndCacheMetadata } from '@cornerstonejs/nifti-volume-loader';
import { RENDERING_ENGINE_ID, TOOL_GROUP_ID, VIEWPORT_ID } from '../../constants';
import getDeviceType from '../devices';
// Module-level cache
let cameraStateCache: any = null;

export async function resetVesselReconstruction() {
  const currentViewport: any = getViewport();
  if (!currentViewport) {
    return;
  }

  if (cameraStateCache) {
    currentViewport.setCamera(cameraStateCache);
  }
  currentViewport.render();
}

export async function activateVesselReconstruction(activeVolumeData: ActiveVolumeData) {
  if (!activeVolumeData.vessel) {
    // eslint-disable-next-line no-console
    console.warn('Vessel data is missing.');
    return;
  }

  const currentViewport = getViewport();
  if (!currentViewport) {
    return;
  }
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);

  const toolGroup = activateToolGroup(TOOL_GROUP_ID.VESSEL_RECONSTRUCTION);
  const { element } = currentViewport;

  // Generate proper NIFTI image IDs for vessel data
  const vesselImageIds = await createNiftiImageIdsAndCacheMetadata({
    url: activeVolumeData.vessel,
  });

  const volumeId = `nifti:${activeVolumeData.vessel}`;

  const vesselVolume = await volumeLoader.createAndCacheVolume(volumeId, {
    imageIds: vesselImageIds,
  });
  await vesselVolume.load();

  // Update viewport configuration
  const viewportInput = [
    {
      viewportId: VIEWPORT_ID,
      type: Enums.ViewportType.VOLUME_3D,
      element,
      defaultOptions: { orientation: Enums.OrientationAxis.CORONAL },
    },
  ];

  renderingEngine?.setViewports(viewportInput);
  const viewport = renderingEngine.getViewport(VIEWPORT_ID) as any;

  // Device-specific performance optimization:
  // Higher sample distance = better performance but lower quality
  const deviceType = getDeviceType();
  const sampleDistance = deviceType === 'mobile' ? 5.0 : 2.0; // Mobile needs more conservative settings

  await setVolumesForViewports(
    renderingEngine,
    [
      {
        volumeId,
        callback: ({ volumeActor }) => {
          if (volumeActor) {
            const mapper = volumeActor.getMapper();
            mapper.setSampleDistance(sampleDistance);
            const volumeProperty = volumeActor.getProperty();
            volumeProperty.setShade(false);
          }
        },
      },
    ],
    [VIEWPORT_ID],
  );

  // adding the specific tools related to interacting and navigating the 3d volume
  toolGroup?.addViewport(VIEWPORT_ID, renderingEngine.id);

  // Apply clinical to viewing vessel reconstructions
  viewport.setProperties({
    preset: 'CT-Coronary-Arteries-3', // Standardized Hounsfield unit range
  });

  viewport.render();
  cameraStateCache = viewport.getCamera();
}

export async function deactivateVesselReconstruction(
  activeVolumesData: ActiveVolumeData,
) {
  const currentViewport = getViewport();
  if (!currentViewport) {
    return;
  }

  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
  if (!renderingEngine) {
    console.error('Rendering engine not found');
    return;
  }

  await loadNewVolume({
    image: activeVolumesData.image,
    lesions: activeVolumesData.lesions,
  });

  // Rest of the orientation and tooling setup remains
  const toolGroup = activateToolGroup(TOOL_GROUP_ID.DEFAULT_VOLUME);
  toolGroup.addViewport(VIEWPORT_ID, renderingEngine.id);

  const { orientation } = useImageOrientation.getState();
  await ChangeOrientation(orientation);
}
