import React from 'react';
import ProcessingIcon from 'components/ui/icons/Processing';
import WarningIcon from 'components/ui/icons/WarningIcon';
import { useTheme } from '@mui/material/styles';
import { ProcessingStatus } from 'types';

type StatusIndicatorProps = {
  status: ProcessingStatus;
};

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const theme = useTheme();

  if (status === 'Processed') {
    return null;
  }

  const Icon = status === 'Processing' ? () => ProcessingIcon : WarningIcon;

  const color = status === 'Processing'
    ? theme.palette.text.secondary
    : theme.palette.warning.main;

  return (
    <div className="dropdown-item-status">
      <Icon />
      <span style={{ color }}>{status}</span>
    </div>
  );
};

export default StatusIndicator;
