/* eslint-disable import/no-extraneous-dependencies */
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import { ImageType, Lesions, Product } from 'types';
import { getViewport } from 'utils/utilities';
import { cache } from '@cornerstonejs/core';
import { IMAGE_TYPES, LESIONS } from '../../constants';

type VolumeActor = any;
type RGB = [number, number, number];

const getVolumeActor = (actorType: string): VolumeActor | null => {
  const viewport = getViewport();
  if (!viewport) return null;

  const actors = viewport.getActors();
  const actor = actors.find((a) => a.referencedId.includes(actorType === 'ischemia' ? 'acute' : actorType));

  return actor || null;
};

export const getDataRange = (volumeActor: VolumeActor): [number, number] | null => {
  const volume = cache.getVolume(volumeActor.referencedId);
  if (volume && volume.voxelManager) {
    const range = volume.voxelManager.getRange();
    if (range && range[0] !== undefined && range[1] !== undefined) {
      return [range[0], range[1]];
    }
  }
  return null;
};

const applyColorTransferFunction = (volumeActor: VolumeActor) => {
  const cfun = vtkColorTransferFunction.newInstance();
  const range = getDataRange(volumeActor);
  if (!range) return;
  // For acute (ischemia), use a traditional heatmap color range

  // Define color points for the Jet palette
  const colorPoints = [
    { value: 0.0, color: [0 / 255, 0 / 255, 127 / 255] }, // #00007F: dark blue
    { value: 0.125, color: [0 / 255, 0 / 255, 255 / 255] }, // #0000FF: blue
    { value: 0.25, color: [0 / 255, 127 / 255, 255 / 255] }, // #007FFF: azure
    { value: 0.375, color: [0 / 255, 255 / 255, 255 / 255] }, // #00FFFF: cyan
    { value: 0.5, color: [127 / 255, 255 / 255, 127 / 255] }, // #7FFF7F: light green
    { value: 0.625, color: [255 / 255, 255 / 255, 0 / 255] }, // #FFFF00: yellow
    { value: 0.75, color: [255 / 255, 127 / 255, 0 / 255] }, // #FF7F00: orange
    { value: 0.875, color: [255 / 255, 0 / 255, 0 / 255] }, // #FF0000: red
    { value: 1.0, color: [127 / 255, 0 / 255, 0 / 255] }, // #7F0000: dark red
  ];

  colorPoints.forEach((point) => {
    const value = range[0] + point.value * (range[1] - range[0]);
    cfun.addRGBPoint(value, point.color[0], point.color[1], point.color[2]);
  });

  volumeActor.actor.getProperty().setRGBTransferFunction(0, cfun);
};
const applyOpacityFunction = (volumeActor: VolumeActor, opacity: number) => {
  const ofun = vtkPiecewiseFunction.newInstance();
  const points = [
    [0, 0],
    [1, opacity],
    [5, opacity],
  ];
  points.forEach(([x, y]) => ofun.addPoint(x, y));
  volumeActor.actor.getProperty().setScalarOpacity(0, ofun);
};

// Modified color and opacity functions with threshold
const applyThresholdedColorAndOpacity = (
  volumeActor: VolumeActor,
  color: RGB,
  opacity: number,
  threshold: number = 0.01,
) => {
  const range = getDataRange(volumeActor);
  if (!range) return;

  // Color function
  const cfun = vtkColorTransferFunction.newInstance();
  cfun.addRGBPoint(range[0], ...color);
  cfun.addRGBPoint(range[1], ...color);
  volumeActor.actor.getProperty().setRGBTransferFunction(0, cfun);

  // Opacity function with threshold
  const ofun = vtkPiecewiseFunction.newInstance();
  ofun.addPoint(0, 0); // Transparent at 0
  ofun.addPoint(threshold - 0.001, 0); // Still transparent just below threshold
  ofun.addPoint(threshold, opacity); // Suddenly opaque at threshold
  ofun.addPoint(1, opacity); // Maintain opacity to maximum

  volumeActor.actor.getProperty().setScalarOpacity(0, ofun);
};

export const setVolumeAppearance = (
  actorType: string,
  color?: RGB,
  isActive: boolean = true,
  threshold: number = 0.01,
) => {
  const volumeActor = getVolumeActor(actorType);
  if (!volumeActor) return;
  if (isActive) {
    volumeActor.actor.getMapper().setSampleDistance(0.5);

    if (actorType.includes('acute')) {
      // Keep gradient for acute lesions
      applyColorTransferFunction(volumeActor);
      applyOpacityFunction(volumeActor, 0.70);
    } else if (color) {
      // For solid lesions, use the thresholding approach
      applyThresholdedColorAndOpacity(volumeActor, color, 0.80, threshold);
    }
  } else {
    // Set opacity to 0 for inactive volumes
    const opacityFunction = vtkPiecewiseFunction.newInstance();
    opacityFunction.addPoint(0, 0);
    opacityFunction.addPoint(1, 0);
    volumeActor.actor.getProperty().setScalarOpacity(0, opacityFunction);
  }
};

const colorMap: Record<Lesions, { type: string; color: RGB | undefined }> = {
  clot: { type: 'clot', color: [1, 0.843, 0] },
  hemo: { type: 'hemo', color: [1, 0.12, 0.12] },
  ischemia: { type: 'acute', color: undefined },
};

export const applyColorMaps = (activeLesionType?: Lesions) => {
  Object.values(colorMap).forEach(({ type, color }) => {
    const isActive = activeLesionType ? type === colorMap[activeLesionType].type : true;
    setVolumeAppearance(type, color, isActive);
  });
};

export const removeColorMaps = () => {
  getViewport()
    .getActors()
    .forEach((actor) => {
      if (['clot', 'hemo', 'acute'].some((type) => actor.referencedId && actor.referencedId.includes(type))) {
        const actorType = ['clot', 'hemo', 'acute'].find((type) => actor.referencedId.includes(type));
        setVolumeAppearance(actorType, undefined, false);
      }
    });
};

export const getDefaultLesion = (): Lesions | null => {
  const acute = getVolumeActor('acute');
  const hemo = getVolumeActor('hemo');
  const clot = getVolumeActor('clot');

  if (acute) return LESIONS.ISCHEMIA;
  if (hemo) return LESIONS.HEMO;
  if (clot) return LESIONS.CLOT;

  return null;
};

export const hasDisplayableValues = (actorType: string): boolean => {
  const volumeActor = getVolumeActor(actorType);
  if (!volumeActor) return false;

  const range = getDataRange(volumeActor);
  if (!range) return false;
  const [min, max] = range;
  return min !== max && !Number.isNaN(min) && !Number.isNaN(max);
};

export function shouldDisplayLesionMenu(
  selectedProduct: Product,
  imageType: ImageType,
): boolean {
  return selectedProduct === 'res' && imageType === IMAGE_TYPES.NCCT;
}

export const applyDefaultColorMap = () => {
  const defaultLesion = getDefaultLesion();
  if (defaultLesion) {
    hasDisplayableValues('acute');
    removeColorMaps();
    const { type, color } = colorMap[defaultLesion];
    setVolumeAppearance(type, color);
  }
};
