import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import './Dropdown.css';
import { formattedImageData } from 'types';

export type UIDropdownProps = {
  items: formattedImageData[];
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  // eslint-disable-next-line no-unused-vars
  renderItem?: (item: formattedImageData, isSelected: boolean) => React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  renderHeader?: (selectedItem?: formattedImageData) => React.ReactNode;
};

const UIDropdown = ({
  items,
  activeIndex,
  setActiveIndex,
  renderItem,
  renderHeader,
}: UIDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isEmptyDropdown = items.length < 1;

  const handleItemClick = (index: number, item: formattedImageData) => {
    setIsDropdownOpen(false);
    if (index !== activeIndex) {
      setActiveIndex(index);
      item?.onClick?.();
    }
  };

  const selectedItem = items[activeIndex];

  return (
    <div className='dropdown'>
      <div className='dropdown-header' onClick={() => {
        if (!isEmptyDropdown) setIsDropdownOpen(!isDropdownOpen);
      }}>
        <span className="dropdown-header-label">{items[activeIndex]?.label}</span>
        {renderHeader ? (
          renderHeader(selectedItem)
        ) : (
          <div className="dropdown-header-content">
            <span>{selectedItem?.label}</span>
          </div>
        )}
        {isDropdownOpen ? (
          <KeyboardArrowUp className={`dropdown-arrow ${isEmptyDropdown ? 'transparent-arrow' : ''}`}/>
        ) : (
          <KeyboardArrowDown className={`dropdown-arrow ${isEmptyDropdown ? 'transparent-arrow' : ''}`}/>
        )}
      </div>

      {isDropdownOpen && (
        <div className='dropdown-list'>
          {items.map((item, index) => (
            <div
              key={index}
              className={`dropdown-item ${index === activeIndex ? 'selected' : ''}`}
              onClick={() => handleItemClick(index, item)}
            >
              {renderItem ? renderItem(item, index === activeIndex) : <span>{item.label}</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UIDropdown;
