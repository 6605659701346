import PatientInfo from 'components/patientinfo/PatientInfo';
import ReviewedByCard from 'components/timeline/ReviewedByCard/ReviewedByCard';
import SpinnerWithoutProgress from 'components/ui/spinner/SpinnerWithoutProgress';
import Switch from 'components/ui/switch/Switch';
import FunctionalText from 'components/ui/text/FunctionalText';
import useView from 'hooks/useView';
import React, { useMemo, useState } from 'react';
import { Patient, ReviewEvent, TimelineData } from 'types';
import { requestTimelineData } from 'utils/requests';
import { formatIsoToDayMonthYear } from 'utils/time';
import ImageAnalysisCard from '../components/timeline/timelineCard/ImageAnalysisCard';
import { VIEW_STATE } from '../constants';
import './Timeline.css';

export const isReviewEvent = (event: TimelineData[number]): event is ReviewEvent => event.event_type === 'review';

export default function Timeline({
  studyDate,
  patientData,
}: {
  studyDate: string;
  patientData: Patient;
}) {
  const [timelineData, setTimelineData] = useState<TimelineData | null>(null);
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    async function fetchTimelineData() {
      try {
        setLoading(true);
        const data = await requestTimelineData();
        setTimelineData(data.timeline_data as TimelineData);
      } catch (error) {
        console.error('Error fetching timeline data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchTimelineData();
  }, []);

  const [, updateView] = useView();
  const [showReviewedBy, setShowReviewedBy] = useState(true);

  const handleBackClick = () => updateView(VIEW_STATE.CASE);

  const groupedData = useMemo(() => {
    if (!timelineData) return {};

    return timelineData
      .filter((item) => showReviewedBy || item.event_type !== 'review')
      .reduce((acc, item) => {
        const eventDate = formatIsoToDayMonthYear(item.event_datetime);
        if (!acc[eventDate]) acc[eventDate] = [];
        acc[eventDate].push(item);
        return acc;
      }, {} as Record<string, TimelineData>);
  }, [timelineData, showReviewedBy]);

  const reviewedByToggle = (
    <div className='show-reviewed-by-container'>
      <span>Show reviewed by</span>
      <Switch isChecked={showReviewedBy} onToggle={() => setShowReviewedBy((prev) => !prev)} />
    </div>
  );

  const ToggleReviewedBySection = (
    <div className='timeline-header header-default-properties'>
      <div className='header-item-style'>
        <FunctionalText text='Back' className='blue-text' onClick={handleBackClick} />
      </div>
      <PatientInfo patientData={patientData} studyDate={studyDate} />
      <div className='timeline-title-container header-item-style'>
        <span>Timeline</span>
        {reviewedByToggle}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div>
        {ToggleReviewedBySection}
        <SpinnerWithoutProgress />
      </div>
    );
  }

  const hasTimelineData = Object.keys(groupedData).length > 0;

  const noDataComponent = (
    <div>
      {ToggleReviewedBySection}
      <div className='no-data-timeline-text'>
        There are currently no events in the timeline to display.
      </div>
    </div>
  );
  if (!hasTimelineData) return noDataComponent;

  return (
    <div>
      {ToggleReviewedBySection}
      <div className='timeline-scrollable-content'>
        {Object.entries(groupedData).map(([date, events]) => (
          <div key={date} className='timeline-date-group'>
            <div className='timeline-date'>{date}</div>
            {events.map((event, index) => (
              <div className='timeline-item' key={`${date}-${index}`}>
                {isReviewEvent(event) ? (
                  <ReviewedByCard timelineObject={event} />
                ) : (
                  <ImageAnalysisCard timelineObject={event} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
