import { getRenderingEngine } from '@cornerstonejs/core';
import SolidColorIcon from 'components/ui/icons/SolidLesionIcon';
import UISelectionMenu from 'components/ui/menus/selection/SelectionMenu';
import useActiveLesion from 'stores/activeLesion';
import React from 'react';
import { Lesions } from 'types';
import areStringsEqual from 'utils/comparisons';
import { applyColorMaps, hasDisplayableValues, removeColorMaps } from 'utils/heatmaps/heatmaps';
import { LESIONS, RENDERING_ENGINE_ID } from '../../constants';
import { IschemiaIcon } from '../ui/icons';

const LesionMenu: React.FC = () => {
  const { activeLesion, setActiveLesion } = useActiveLesion();

  const lesionOptions: Record<Lesions, { title: string; icon: React.ReactElement }> = {
    ischemia: { title: LESIONS.ISCHEMIA, icon: <IschemiaIcon /> },
    hemo: { title: LESIONS.HEMO, icon: <SolidColorIcon color='#FF1F1F' /> },
    clot: { title: LESIONS.CLOT, icon: <SolidColorIcon color='#FFD700' /> },
  };

  const handleLesionClick = (lesion: Lesions) => {
    if (activeLesion && areStringsEqual(activeLesion, lesion)) {
      removeColorMaps();
      setActiveLesion(null);
    } else {
      applyColorMaps(lesion);
      setActiveLesion(lesion);
    }
    getRenderingEngine(RENDERING_ENGINE_ID)?.render();
  };

  const menuContent = Object.entries(lesionOptions).map(([lesion, { title }]) => ({
    title,
    onClick: () => handleLesionClick(lesion as Lesions),
    className: hasDisplayableValues(lesion as Lesions) ? '' : 'inactive',
  }));

  return (
    <UISelectionMenu
      menuContent={menuContent}
      activeIcon={activeLesion ? lesionOptions[activeLesion].icon : undefined}
      isToggleMenu={true}
      menuItemClassName='lesion-menu-item'
      activeItem={activeLesion}
    />
  );
};

export default LesionMenu;
