import { Enums,
  PanTool,
  ZoomTool,
  StackScrollTool,
  TrackballRotateTool } from '@cornerstonejs/tools';
import { TOOL_GROUP_ID } from '../../../constants';
import CustomWindowLevelTool from '../CustomWindowLevelTool';

const {
  MouseBindings,
} = Enums;

// Add JSDoc for clarity
type ToolBinding = {
  mouseButton?: number
  numTouchPoints?: number
};

type ToolConfiguration = {
  configuration?: Record<string, unknown>
};

type ToolConstructor =
  | typeof PanTool
  | typeof ZoomTool
  | typeof StackScrollTool
  | typeof TrackballRotateTool
  | typeof CustomWindowLevelTool;

/** Configuration for a group of tools and their input bindings */
export type ToolGroupConfig = {
  tools: ToolConstructor[];
  bindings: Record<string, ToolBinding[]>;
  settings?: Record<string, ToolConfiguration>;
};

// Common binding patterns
const primaryClickBinding = { mouseButton: MouseBindings.Primary };
const secondaryClickBinding = { mouseButton: MouseBindings.Secondary };
const twoFingerGesture = { numTouchPoints: 2 };
const oneFingerGesture = { numTouchPoints: 1 };

const preventRenderOnChangeConfig = {
  configuration: {
    preventRenderOnChange: true,
  },
};

export const toolGroups: Record<TOOL_GROUP_ID, ToolGroupConfig> = {
  [TOOL_GROUP_ID.CTP]: {
    tools: [PanTool, ZoomTool],
    bindings: {
      [PanTool.toolName]: [secondaryClickBinding],
      [ZoomTool.toolName]: [twoFingerGesture, primaryClickBinding],
    },
    settings: {},

  },
  [TOOL_GROUP_ID.DEFAULT_VOLUME]: {
    tools: [CustomWindowLevelTool, PanTool, ZoomTool, StackScrollTool],
    bindings: {
      [CustomWindowLevelTool.toolName]: [oneFingerGesture],
      [PanTool.toolName]: [secondaryClickBinding],
      [ZoomTool.toolName]: [twoFingerGesture, primaryClickBinding],
      [StackScrollTool.toolName]: [],
    },
    settings: {},
  },
  [TOOL_GROUP_ID.VESSEL_RECONSTRUCTION]: {
    tools: [TrackballRotateTool, ZoomTool],
    bindings: {
      [TrackballRotateTool.toolName]: [secondaryClickBinding, oneFingerGesture],
      [ZoomTool.toolName]: [primaryClickBinding, twoFingerGesture],
    },
    settings: {
      [TrackballRotateTool.toolName]: preventRenderOnChangeConfig,
      [ZoomTool.toolName]: preventRenderOnChangeConfig,
    },
  },
};

export default toolGroups;
