import { DeviceType } from '../types';
/**
 * Detects whether the current device is mobile or desktop based on the user agent string.
 *
 * @returns 'mobile' | 'desktop' - the type of device being used
 *
 * @example
 * const deviceType = getDeviceType();
 *
 * @remarks
 * This function uses a regular expression to test the user agent string for common mobile devices.
 * It returns 'mobile' if any of the following strings are found in the user agent:
 * - 'Mobile'
 * - 'Android'
 * - 'iPhone' or 'iPod'
 * - 'IEMobile'
 * - 'BlackBerry'
 * - 'Kindle'
 * - 'Silk'
 * - 'Opera Mini'
 * Otherwise, it returns 'desktop'.
 */
export default function getDeviceType(): DeviceType {
  const isMobile = /Mobile|Android|iP(?:hone|od)|IEMobile|BlackBerry|kindle|Silk|Opera Mini/i.test(
    navigator.userAgent,
  );
  return isMobile ? 'mobile' : 'desktop';
}
